
            @import 'src/styles/mixins.scss';
        
.beforeStyle {
    position: relative;
    &::before {
        content: "";

        width: 340px;

        height: 680px;
        position: absolute;
        top: -80px;
        right: -100px;
        background-image: url("/assets/images/sideImgBefore.png");

        z-index: -10;
        @include lg {
            bottom: -55px;
            left: -160px;
        }
    }
}
