
            @import 'src/styles/mixins.scss';
        
.oneBoxStyle {
    border-radius: 30px;

    box-shadow: 0px 4px 25px -1px rgba(0, 0, 0, 0.1);
}
.animation {
    transition: all 0.3s ease-in-out;
}
.animation:hover {
    transform: translateY(-7px);
    transition: all 0.3s ease-in-out;
}
